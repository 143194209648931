import React, {Component} from "react"

// TODO: delete this file after finish testing

const codeStyle = {
    color: '#ed143d',
    backgroundColor: '#f1f1f1',
    padding: '2px',
    fontSize: '105%'
}

export default class Callback extends Component {
    componentDidMount = async () => {
        if (window) {
            window.runFromAndroid = function (message) {
                alert(message);
            }
        }
    }

    componentDidUpdate() {

    }

    handleOnClickClose() {
        try {
            if (window) {
                window.close();
            }
        } catch (e) {
            console.error(e.message);
        }
    };

    handleOnClickFirst(event) {
        if (window && window.AndroidFunction && window.AndroidFunction.first) {
            window.AndroidFunction.first();
        }
    };

    handleOnClickSecond(event) {
        if (window && window.AndroidFunction && window.AndroidFunction.second) {
            window.AndroidFunction.second("abacaba");
        }
    };

    handleOnClickThird(event) {
        if (window && window.AndroidFunction && window.AndroidFunction.third) {
            window.AndroidFunction.third(JSON.stringify({
                params: {
                    message: "ceeeeeeeeeeeeeeeeeeeeeeeb!"
                },
                callbackName: "runFromAndroid"
            }))
        }
    };

    render() {
        return (
            <div>


                <hr/>
                <button onClick={this.handleOnClickClose}>close()</button>
                <br/>
                <br/>
                <hr/>
                <button onClick={this.handleOnClickFirst}>first()</button>
                <br/>
                <br/>
                <span>call <code style={codeStyle}>AndroidFunction.first()</code></span>
                <hr/>
                <button onClick={this.handleOnClickSecond}>second("abacaba")</button>
                <br/>
                <br/>
                <span>call <code style={codeStyle}>AndroidFunction.second("abacaba")</code></span>
                <hr/>
                <button onClick={this.handleOnClickThird}>trigger method third(&#123;params: &#123;&#125;, callbackName: ""&#125;)</button>
                <br/>
                <br/>
                <span>call <code style={codeStyle}>AndroidFunction.third("&#123;params: &#123;message: "ceeeeeeeeeeeeeeeeeeeeeeeb!"&#125;, callbackName: "runFromAndroid"&#125;")</code></span>
                <hr/>
            </div>
        )
    }
}
